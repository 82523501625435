import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/nftx-developers.github.io/nftx-developers.github.io/packages/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const PageDescription = makeShortcode("PageDescription");
const Row = makeShortcode("Row");
const Column = makeShortcode("Column");
const ResourceCard = makeShortcode("ResourceCard");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <PageDescription mdxType="PageDescription">
      <p>{`For anyone working on a Gatsby-themed site, this updated Sketch kit has all the
components, patterns, and sample layouts that have been developed by teams
within the IBM ecosystem.`}</p>
      <p>{`The Sketch kit is a great resource for designers working on new pages for PAL
sites or any other sites using the Gatsby theme.`}</p>
    </PageDescription>
    <h2>{`Resources`}</h2>
    <Row className="resource-card-group" mdxType="Row">
  <Column colMd={4} colLg={4} noGutterSm mdxType="Column">
    <ResourceCard subTitle="Gatsby theme Sketch kit" href="sketch://add-library/cloud/304313c1-29a8-4946-a6f0-51dbec953bc2" actionIcon="download" mdxType="ResourceCard">
          <span {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "200px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "95%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAATCAYAAACQjC21AAAACXBIWXMAAAsTAAALEwEAmpwYAAADe0lEQVQ4y4WTT2hcdRDHv2/f1rpKFFsseO1JUFFviggVPBT/nISCeNAc2ibZl7XENGmOVvRQRWqoVYMigkYS9+1mN4sHDy0oNHYtBHe3hwaUmjZ2897L262m63Z3fzMy8162aQ/6g+E3zMz38+Y3wwPnABIrwqJFoMt7QcvJXfSr/TBVB/aZZbxKK0+N0cozb6lfG3hOc8vJ3R3eoxoqwGIXIBcxbBEJ/hrgL7GLzts/m1pyk6o726YCptp9TOvTTOunmGr3s8YkJzXn7TJ/hQf5m5ghQO3wB8viOYCzcOmczebiTjYVi6l6N1P9Q8PNs11unulS/aSh6j0kOamhJZs5iwJnAVpKYAtoS4BdjLMLpvKOjqnZhio20Z/HiZtnicICUbgQ+dfeJaokSWvKOzrkgtnFlMCEBS7o25+WBOUEaBtTBdPVCQEwhQvM4aKa+s0zTFenSGt+SRDlLFati2eFBa4gxXmscA7MefToApjWRomv/8jUKDE1v2eOTXyJaW7tCEmtaGLtb3wB96I3i6yZB5t5qyM3XXqFeKNE7H3L5M0x+3N6b/clxxslppUDFGu7cvdmUUA4jtNBBrzxJrqBA2p/PkBcShHlU8wLKaaF6N7ua66UoptfDFAwClKtMMYxgxsfAP4IvvPTYG8IncakzoPYBVFWfRm6Wjwr8TXfOAb2DqMjWn8E+dY0gGuvA423kfQdLAVOBG1/AuYiSMG5eFkyp1z8sSKo/SnYH0JHNL6DcjiFu+pvAPAd2L4D+KPYEzi47A+DG5Poaie5CEq3uiON5cCNY+hKbeBg1XfwkDIc2PDT26AOHgscbHpD4H8+Ro+Lfcj27rh9Gj2pCRy0fAdPKCONpNwRcORWIMhgv3w5nFCA2QL1n+/CyJy1uwxe6sOGI448Fd5hwB+8DXpIuzwFo7PcWk4RJJ1rdxmMbDXivQb4h1QXHXGCo0BwRL+SqA8C3hDeC8cV1JW5xfPshkd1syfWB6NaX7RjUWO3HQloMgOrfgC48jKwfhCzrZPaWUes9RHYO4j5Ky8A5lHVWDL74E5YH+r0zfp9H5AHEuEEfjJz8jeAw0mcKwPJy8/rUxPx/P77CNAbjgR/7Acu7sUD7RmstmewdukR7F59MYZFNbj52f8Aw7F482ng+nHYf58Abkzj8dY0ntx8H/jrHdi6uHQ0uzvPv7ZAHJs9yKMlAAAAAElFTkSuQmCC')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Sketch icon",
              "title": "Sketch icon",
              "src": "/static/28b17f4b368ca58d4d47e5136b85f0d3/59afc/sketch-icon.png",
              "srcSet": ["/static/28b17f4b368ca58d4d47e5136b85f0d3/59afc/sketch-icon.png 200w"],
              "sizes": "(max-width: 200px) 100vw, 200px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy",
              "decoding": "async"
            }}></img>{`
    `}</span>
  </ResourceCard>
      </Column>
    </Row>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      